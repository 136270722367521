import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "pencapaianList";
const modelPath = "/anggota/mahasiswa/";

class PencapaianList extends ListModelBase {
  constructor() {
    let filterMap = {
      angkatan: null,
      stase: null,
      cari: null
    }
    super(modelName, modelPath, filterMap);
  }
}

export default PencapaianList;